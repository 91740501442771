
const items = [
    {   
        id: 1,
        name: "Электроника",
    },

    {
        id: 2,    
        name: "Смартфоны и гаджеты",
    },

    {
        id: 3,    
        name: "Мобильные телефоны",
    },  

    {
        id: 4,
        name: "Apple",
    },  
];

export default items;