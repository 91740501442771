
const volumes = [
    {
        id: "volume-min",
        volume: "128 ГБ",
    },

    {
        id: "volume-mid",
        volume: "256 ГБ",
    },

    {
        id: "volume-max",
        volume: "512 ГБ",
    }    

];

export default volumes;









































// const volumes = [
//     {
//         id: "volume-min",
//         volume: "128 ГБ",
//     },

//     {
//         id: "volume-mid",
//         volume: "256 ГБ",
//     },

//     {
//         id: "volume-max",
//         volume: "512 ГБ",
//     }    

// ];

// export default volumes;